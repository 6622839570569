import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import {
  DashboardCoseDaFare
} from "../../_metronic/_partials";

export const Valutazioni = () => {

  const suhbeader = useSubheader();
  suhbeader.setTitle("Le mie valutazioni");

  return (<>
    <div className="row">
      <div className="col-lg-6 col-xxl-6">
        <div className={`card card-custom`}>

          {/* begin::Header */}
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                Valutazioni
          </span>
              <span className="text-muted mt-3 font-weight-bold font-size-sm">
                Le mie valutazioni
          </span>
            </h3>
            <div className="card-toolbar">
              <a
                href="#"
                className="btn btn-success font-weight-bolder font-size-sm"
              >
                <span className="svg-icon svg-icon-md svg-icon-white">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Add-user.svg"
                    )}
                    className="h-50 align-self-center"
                  ></SVG>
                </span>
            Richiedi revisione
          </a>
            </div>
          </div>
          {/* end::Header */}


          {/* begin::Body */}
          <div className="card-body py-0">



            {/* begin::Heading */}
            <div className="row">
              <label className="col-xl-3"></label>
              <div className="col-lg-9 col-xl-6">
                <h5 className="font-weight-bold mb-6">Le mie Competenze:</h5>
              </div>
            </div>
            {/* begin::Form Group */}

            {/* begin::Form Group */}
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">Lavoro di gruppo:</label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <div className={`form-control form-control-lg form-control-solid`}>

                    <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                    <span style={{ "marginLeft": "5px", "padding": "10px 0 0 0 0" }} className="text-muted font-weight-bold"> 5 su 5 </span>

                  </div>
                </div>
              </div>
            </div>
            {/* begin::Form Group */}

            {/* begin::Form Group */}
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">Competenze:</label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <div className={`form-control form-control-lg form-control-solid`}>

                    <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                    <span style={{ "marginLeft": "5px", "padding": "10px 0 0 0 0" }} className="text-muted font-weight-bold"> 5 su 5 </span>

                  </div>
                </div>
              </div>
            </div>
            {/* begin::Form Group */}

            {/* begin::Form Group */}
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">Competenze 1:</label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <div className={`form-control form-control-lg form-control-solid`}>

                    <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                    <span style={{ "marginLeft": "5px", "padding": "10px 0 0 0 0" }} className="text-muted font-weight-bold"> 5 su 5 </span>

                  </div>
                </div>
              </div>
            </div>
            {/* begin::Form Group */}

            {/* begin::Form Group */}
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">Competenze 2:</label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <div className={`form-control form-control-lg form-control-solid`}>

                    <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                    <span style={{ "marginLeft": "5px", "padding": "10px 0 0 0 0" }} className="text-muted font-weight-bold"> 5 su 5 </span>

                  </div>
                </div>
              </div>
            </div>
            {/* begin::Form Group */}

            {/* begin::Form Group */}
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">Competenze 3:</label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <div className={`form-control form-control-lg form-control-solid`}>

                    <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                    <span style={{ "marginLeft": "5px", "padding": "10px 0 0 0 0" }} className="text-muted font-weight-bold"> 5 su 5 </span>

                  </div>
                </div>
              </div>
            </div>
            {/* begin::Form Group */}

            {/* begin::Form Group */}
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">Competenze 4:</label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <div className={`form-control form-control-lg form-control-solid`}>

                    <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                    <span style={{ "marginLeft": "5px", "padding": "10px 0 0 0 0" }} className="text-muted font-weight-bold"> 5 su 5 </span>

                  </div>
                </div>
              </div>
            </div>
            {/* begin::Form Group */}

            {/* begin::Form Group */}
            <div style={{"borderTop":"1px solid #ddd","paddingTop":"20px"}} className="form-group row">
            <h5 style={{"paddingTop":"10px"}} className="font-weight-bold mb-6">Valutazione Finale:</h5>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <div className={`form-control form-control-lg form-control-solid`}>

                    <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                    <span style={{ "marginLeft": "5px", "padding": "10px 0 0 0 0" }} className="text-muted font-weight-bold"> 5 su 5 </span>

                  </div>
                </div>
              </div>
            </div>
            {/* begin::Form Group */}

          </div>
          {/* end::Body */}
        </div>
      </div>






      <div className="col-lg-6 col-xxl-6">
        <div className={`card card-custom`}>

          {/* begin::Header */}
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                Il mio CV
          </span>
              <span className="text-muted mt-3 font-weight-bold font-size-sm">
                Il mio curriculum Vitae
          </span>
            </h3>
            <div className="card-toolbar">
              <a
                href="#"
                className="btn btn-success font-weight-bolder font-size-sm"
              >
                <span className="svg-icon svg-icon-md svg-icon-white">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Files/Uploaded-file.svg"
                    )}
                    className="h-50 align-self-center"
                  ></SVG>
                </span>
            Ricarica CV aggiornato
          </a>
            </div>
          </div>
          {/* end::Header */}


          {/* begin::Body */}
          <div className="card-body py-0" style={{ "textAlign": "center" }}>
            <img style={{ "border": "#ddd solid 1px", "margin": "3%" }} src={toAbsoluteUrl("/media/curriculum/1.jpg")} />
          </div>
          {/* end::Body */}
        </div>
      </div>

    </div>
  </>);
};
