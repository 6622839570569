/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";

export function AsideMenuList({ layoutProps }) {

  const user = useSelector((state) => state.auth.user, shallowEqual);

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li style={user.id != 1 ? { "display": "none" } : { "display": "" }}
          className={`menu-item ${getMenuItemActive("/all-agent", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/all-agent">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Il mio Team</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/buste-paga", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/buste-paga">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">{user.id == 1 ? "Buste Paga" : "Le mie buste Paga"}</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/piano-feriale", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/piano-feriale">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">{user.id == 1 ? "Piano Feriale" : "Il mio piano feriale"}</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/permessi", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/permessi">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">{user.id == 1 ? "Permessi" : "I miei permessi"}</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/document-repository", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/document-repository">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Document Repository</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/error",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/error">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Contact1.svg")}
              />
            </span>
            <span className="menu-text">Il mio Account</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Il mio Account</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/user-profile")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/user-profile">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-profile"></i>
                  </span>
                  <span className="menu-text">Il mio profilo</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li style={user.id == 1 ? { "display": "none" } : { "display": "" }}
                className={`menu-item ${getMenuItemActive("/valutazioni")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/valutazioni">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon-star"></i>
                  </span>
                  <span className="menu-text">Le mie valutazioni</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/attivita")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/attivita">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon2-rocket-1"></i>
                  </span>
                  <span className="menu-text">Le mie attività</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/cose-da-fare")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/cose-da-fare">
                  <span className="svg-icon menu-icon">
                    <i className="flaticon2-hourglass"></i>
                  </span>
                  <span className="menu-text">Cose da fare</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
