import React from "react";
import { useSubheader } from "../../_metronic/layout";
import {
  DashboardAllAgent
} from "../../_metronic/_partials";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export const Permessi = () => {

  const suhbeader = useSubheader();
  suhbeader.setTitle("Permessi");

  return (
    <div className={`card card-custom`}>
      {/* Head */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">Richieste di Permessi <small>(Tutto il team)</small></span>
        </h3>
        <div className="card-toolbar">
          <a href="#" className="btn btn-info font-weight-bolder font-size-sm mr-3">Scarica Report</a>
        </div>
      </div>
      {/* Body */}
      <div className="card-body pt-0 pb-3">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-7" style={{ minWidth: "250px" }}><span className="text-dark-75">Agente</span></th>
                  <th style={{ minWidth: "100px" }}>Da</th>
                  <th style={{ minWidth: "100px" }}>A</th>
                  <th style={{ minWidth: "100px" }}>Reperibilità</th>
                  <th style={{ minWidth: "130px" }}>Giorni feriali disponibili</th>
                  <th style={{ minWidth: "130px" }}>Causale</th>
                  <th style={{ minWidth: "130px" }}>Stato</th>
                  <th style={{ minWidth: "80px" }} />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50 symbol-light mr-4">
                        <span className="symbol-label">

                          <img style={{ "width": "50px", "height": "50px", "borderRadius": "4px" }} src={toAbsoluteUrl("/media/users/100_1.jpg")} />

                        </span>
                      </div>
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Mario
                        Rossi</a>
                        <span className="text-muted font-weight-bold d-block">Team Director</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      01/03/2021
                    </span>
                    <span className="text-success font-weight-bold">
                      Data Disponiibile
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      03/03/2021
                    </span>
                    <span className="text-success font-weight-bold">
                      Data Disponibile
                    </span>
                  </td>
                  <td>
                    <span className="text-success font-weight-bolder d-block font-size-lg">
                      Reperibile
                    </span>
                  </td>
                  <td>
                    <span className="text-success font-weight-bolder d-block font-size-lg">
                      30
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      Ho bisogno di 3 giorni per motivi personali
                    </span>
                  </td>
                  <td>
                    <span className="text-success font-weight-bolder d-block font-size-lg">
                      Confermato
                    </span>
                  </td>
                  <td className="pr-0 text-right">
                    <a href="#" className="btn btn-light-warning font-weight-bolder font-size-sm">Modifica</a>
                  </td>
                </tr>
                <tr>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50 symbol-light mr-4">
                        <span className="symbol-label">

                          <img style={{ "width": "50px", "height": "50px", "borderRadius": "4px" }} src={toAbsoluteUrl("/media/users/100_8.jpg")} />

                        </span>
                      </div>
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Mario
                        Loffredo</a>
                        <span className="text-muted font-weight-bold d-block">Mobile Specialist</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      16/06/2021
                    </span>
                    <span className="text-success font-weight-bold">
                      Data Disponiibile
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      16/06/2021
                    </span>
                    <span className="text-success font-weight-bold">
                      Data Disponibile
                    </span>
                  </td>
                  <td>
                    <span className="text-success font-weight-bolder d-block font-size-lg">
                      Reperibile
                    </span>
                  </td>
                  <td>
                    <span className="text-success font-weight-bolder d-block font-size-lg">
                      30
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      Ho bisogno di 1 giorno
                    </span>
                  </td>
                  <td>
                    <span className="text-warning font-weight-bolder d-block font-size-lg">
                      Richiesta effettuata
                    </span>
                  </td>
                  <td className="pr-0 text-right">
                    <a href="#" className="btn btn-light-warning font-weight-bolder font-size-sm">Modifica</a>
                  </td>
                </tr>
                <tr>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50 symbol-light mr-4">
                        <span className="symbol-label">

                          <img style={{ "width": "50px", "height": "50px", "borderRadius": "4px" }} src={toAbsoluteUrl("/media/users/100_6.jpg")} />

                        </span>
                      </div>
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Michele
                          Loffredo</a>
                        <span className="text-muted font-weight-bold d-block">Mobile Specialist</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      01/08/2021
                    </span>
                    <span className="text-success font-weight-bold">
                      Data Disponiibile
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      05/08/2021
                    </span>
                    <span className="text-success font-weight-bold">
                      Data Disponibile
                    </span>
                  </td>
                
                  <td>
                    <span className="text-success font-weight-bolder d-block font-size-lg">
                      Reperibile
                    </span>
                  </td>
                  <td>
                    <span className="text-success font-weight-bolder d-block font-size-lg">
                      30
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      Ho bisogno di 4 giorni per motivi familiani
                    </span>
                  </td>
                  <td>
                    <span className="text-success font-weight-bolder d-block font-size-lg">
                      Confermato
                    </span>
                  </td>
                  <td className="pr-0 text-right">
                    <a href="#" className="btn btn-light-warning font-weight-bolder font-size-sm">Modifica</a>
                  </td>
                </tr>
                <tr>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50 symbol-light mr-4">
                        <span className="symbol-label">

                          <img style={{ "width": "50px", "height": "50px", "borderRadius": "4px" }} src={toAbsoluteUrl("/media/users/100_2.jpg")} />

                        </span>
                      </div>
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Nicola
                          De Simone</a>
                        <span className="text-muted font-weight-bold d-block">Mobile Guide Specialist</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      01/08/2021
                    </span>
                    <span className="text-danger font-weight-bold">
                      Data Non Disponiibile
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      01/08/2021
                    </span>
                    <span className="text-danger font-weight-bold">
                      Data Non Disponibile
                    </span>
                  </td>
                  <td>
                    <span className="text-success font-weight-bolder d-block font-size-lg">
                      Reperibile
                    </span>
                  </td>
                  <td>
                    <span className="text-danger font-weight-bolder d-block font-size-lg">
                      5
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      Ho bisogno di 1 giorno
                    </span>
                  </td>
                  <td>
                    <span className="text-danger font-weight-bolder d-block font-size-lg">
                      Non Confermato
                    </span>
                  </td>
                  <td className="pr-0 text-right">
                    <a href="#" className="btn btn-light-warning font-weight-bolder font-size-sm">Modifica</a>
                  </td>
                </tr>
                <tr>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50 symbol-light mr-4">
                        <span className="symbol-label">

                          <img style={{ "width": "50px", "height": "50px", "borderRadius": "4px" }} src={toAbsoluteUrl("/media/users/100_5.jpg")} />

                        </span>
                      </div>
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Antonio
                          Broegg</a>
                        <span className="text-muted font-weight-bold d-block">Mobile Specialist</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      01/07/2021
                    </span>
                    <span className="text-success font-weight-bold">
                      Data Disponiibile
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      15/07/2021
                    </span>
                    <span className="text-success font-weight-bold">
                      Data Disponibile
                    </span>
                  </td>
                  <td>
                    <span className="text-danger font-weight-bolder d-block font-size-lg">
                      Non Reperibile
                    </span>
                  </td>
                  <td>
                    <span className="text-success font-weight-bolder d-block font-size-lg">
                      30
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      Ho bisogno di 1 giorno
                    </span>
                  </td>
                  <td>
                    <span className="text-success font-weight-bolder d-block font-size-lg">
                      Confermato
                    </span>
                  </td>
                  <td className="pr-0 text-right">
                    <a href="#" className="btn btn-light-warning font-weight-bolder font-size-sm">Modifica</a>
                  </td>
                </tr>
                <tr>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50 symbol-light mr-4">
                        <span className="symbol-label">

                          <img style={{ "width": "50px", "height": "50px", "borderRadius": "4px" }} src={toAbsoluteUrl("/media/users/100_3.jpg")} />

                        </span>
                      </div>
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Luigi
                          Mazza</a>
                        <span className="text-muted font-weight-bold d-block">Team Specialist</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      1/03/2021
                    </span>
                    <span className="text-danger font-weight-bold">
                      Data Non Disponiibile
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      03/03/2021
                    </span>
                    <span className="text-danger font-weight-bold">
                      Data Non Disponibile
                    </span>
                  </td>
                  <td>
                    <span className="text-danger font-weight-bolder d-block font-size-lg">
                      Non Reperibile
                    </span>
                  </td>
                  <td>
                    <span className="text-warning font-weight-bolder d-block font-size-lg">
                      15
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      Ho bisogno di 2 giorni per motivi di salute
                    </span>
                  </td>
                  <td>
                    <span className="text-warning font-weight-bolder d-block font-size-lg">
                      Richiesta effettuata
                    </span>
                  </td>
                  <td className="pr-0 text-right">
                    <a href="#" className="btn btn-light-warning font-weight-bolder font-size-sm">Modifica</a>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
