import React from "react";
import {useSubheader} from "../../_metronic/layout";
import {
  DashboardAttivita
} from "../../_metronic/_partials";

export const Attivita = () => {

  const suhbeader = useSubheader();
  suhbeader.setTitle("Le mie attività");

  return <DashboardAttivita />;
};
