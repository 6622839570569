import React from "react";
import {useSubheader} from "../../_metronic/layout";
import {
  DashboardAllAgent
} from "../../_metronic/_partials";

export const AllAgent = () => {

  const suhbeader = useSubheader();
  suhbeader.setTitle("Il mio Team");

  return <DashboardAllAgent />;
};
