/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { Button, Modal, Form, Col } from 'react-bootstrap';

export function AdvanceTablesWidget4AllAgent({ className }) {

  const SchedaAgente = (props) => {
    return (
      <Modal
        {...props}
        size="xl"

        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Scheda Utente
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6 col-xxl-6">
              <div className={`card card-custom`}>

                {/* begin::Header */}
                <div className="card-header border-0 py-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label font-weight-bolder text-dark">
                      Valutazioni
          </span>
                    <span className="text-muted mt-3 font-weight-bold font-size-sm">
                      Le valutazioni dell'utente
          </span>
                  </h3>
                  <div className="card-toolbar">

                  </div>
                </div>
                {/* end::Header */}


                {/* begin::Body */}
                <div className="card-body py-0">



                  {/* begin::Heading */}
                  <div className="row">
                    <label className="col-xl-3"></label>
                    <div className="col-lg-9 col-xl-6">
                      <h5 className="font-weight-bold mb-6">Competenze:</h5>
                    </div>
                  </div>
                  {/* begin::Form Group */}

                  {/* begin::Form Group */}
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Lavoro di gruppo:</label>
                    <div className="col-lg-9 col-xl-6">
                      <div>
                        <div className={`form-control form-control-lg form-control-solid`}>

                          <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                          <span style={{ "marginLeft": "5px", "padding": "10px 0 0 0 0" }} className="text-muted font-weight-bold"> 5 su 5 </span>

                        </div>
                      </div>
                    </div>
                  </div>
                  {/* begin::Form Group */}

                  {/* begin::Form Group */}
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Competenze:</label>
                    <div className="col-lg-9 col-xl-6">
                      <div>
                        <div className={`form-control form-control-lg form-control-solid`}>

                          <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                          <span style={{ "marginLeft": "5px", "padding": "10px 0 0 0 0" }} className="text-muted font-weight-bold"> 5 su 5 </span>

                        </div>
                      </div>
                    </div>
                  </div>
                  {/* begin::Form Group */}

                  {/* begin::Form Group */}
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Competenze 1:</label>
                    <div className="col-lg-9 col-xl-6">
                      <div>
                        <div className={`form-control form-control-lg form-control-solid`}>

                          <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                          <span style={{ "marginLeft": "5px", "padding": "10px 0 0 0 0" }} className="text-muted font-weight-bold"> 5 su 5 </span>

                        </div>
                      </div>
                    </div>
                  </div>
                  {/* begin::Form Group */}

                  {/* begin::Form Group */}
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Competenze 2:</label>
                    <div className="col-lg-9 col-xl-6">
                      <div>
                        <div className={`form-control form-control-lg form-control-solid`}>

                          <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                          <span style={{ "marginLeft": "5px", "padding": "10px 0 0 0 0" }} className="text-muted font-weight-bold"> 5 su 5 </span>

                        </div>
                      </div>
                    </div>
                  </div>
                  {/* begin::Form Group */}

                  {/* begin::Form Group */}
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Competenze 3:</label>
                    <div className="col-lg-9 col-xl-6">
                      <div>
                        <div className={`form-control form-control-lg form-control-solid`}>

                          <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                          <span style={{ "marginLeft": "5px", "padding": "10px 0 0 0 0" }} className="text-muted font-weight-bold"> 5 su 5 </span>

                        </div>
                      </div>
                    </div>
                  </div>
                  {/* begin::Form Group */}

                  {/* begin::Form Group */}
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Competenze 4:</label>
                    <div className="col-lg-9 col-xl-6">
                      <div>
                        <div className={`form-control form-control-lg form-control-solid`}>

                          <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                          <span style={{ "marginLeft": "5px", "padding": "10px 0 0 0 0" }} className="text-muted font-weight-bold"> 5 su 5 </span>

                        </div>
                      </div>
                    </div>
                  </div>
                  {/* begin::Form Group */}

                  {/* begin::Form Group */}
                  <div style={{ "borderTop": "1px solid #ddd", "paddingTop": "20px" }} className="form-group row">
                    <h5 style={{ "paddingTop": "10px" }} className="font-weight-bold mb-6">Valutazione Finale:</h5>
                    <div className="col-lg-9 col-xl-6">
                      <div>
                        <div className={`form-control form-control-lg form-control-solid`}>

                          <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                          <span style={{ "marginLeft": "5px", "padding": "10px 0 0 0 0" }} className="text-muted font-weight-bold"> 5 su 5 </span>

                        </div>
                      </div>
                    </div>
                  </div>
                  {/* begin::Form Group */}

                </div>
                {/* end::Body */}
              </div>
            </div>






            <div className="col-lg-6 col-xxl-6">
              <div className={`card card-custom`}>

                {/* begin::Header */}
                <div className="card-header border-0 py-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label font-weight-bolder text-dark">
                      Il CV dell'utente
          </span>
                    <span className="text-muted mt-3 font-weight-bold font-size-sm">
                      Il curriculum Vitae dell'utente
          </span>
                  </h3>
                  <div className="card-toolbar">
                  </div>
                </div>
                {/* end::Header */}


                {/* begin::Body */}
                <div className="card-body py-0" style={{ "textAlign": "center" }}>
                  <img style={{ "border": "#ddd solid 1px", "margin": "3%" }} src={toAbsoluteUrl("/media/curriculum/1.jpg")} />
                </div>
                {/* end::Body */}
              </div>
            </div>

          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <SchedaAgente show={modalShow} onHide={() => setModalShow(false)} />
      <div className={`card card-custom ${className}`}>
        {/* Head */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">Il mio Team</span>
          </h3>
        </div>
        {/* Body */}
        <div className="card-body pt-0 pb-3">
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                  <tr className="text-left text-uppercase">
                    <th className="pl-7" style={{ minWidth: "250px" }}><span className="text-dark-75">Agente</span></th>
                    <th style={{ minWidth: "100px" }}>Profitti</th>
                    <th style={{ minWidth: "100px" }}>Provvigioni</th>
                    <th style={{ minWidth: "100px" }}>Località</th>
                    <th style={{ minWidth: "130px" }}>Valutazione</th>
                    <th style={{ minWidth: "80px" }} />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">

                            <img style={{ "width": "50px", "height": "50px", "borderRadius": "4px" }} src={toAbsoluteUrl("/media/users/100_1.jpg")} />

                          </span>
                        </div>
                        <div>
                          <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Mario
                          Rossi</a>
                          <span className="text-muted font-weight-bold d-block">Mobile Specialist</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €8,000,000
                      </span>
                      <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €520
                      </span>
                      <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Lombardia
                      </span>
                      <span className="text-muted font-weight-bold">
                        Milano
                      </span>
                    </td>
                    <td>
                      <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        5 su 5
                      </span>
                    </td>
                    <td className="pr-0 text-right">
                      <a href="#" className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => setModalShow(true)} onClick={() => setModalShow(true)}>Scheda Utente</a>
                    </td>
                  </tr>
                  <tr>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                            <img style={{ "width": "50px", "height": "50px", "borderRadius": "4px" }} src={toAbsoluteUrl("/media/users/100_3.jpg")} />
                          </span>
                        </div>
                        <div>
                          <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Michele
                          Loffredo</a>
                          <span className="text-muted font-weight-bold d-block">Team Manager</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €3,000,000
                      </span>
                      <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €120
                      </span>
                      <span className="text-warning font-weight-bold">
                        Da Verificare
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Campania
                      </span>
                      <span className="text-muted font-weight-bold">
                        Napoli
                      </span>
                    </td>
                    <td>
                      <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        5 su 5
                      </span>
                    </td>
                    <td className="pr-0 text-right">
                      <a href="#" className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => setModalShow(true)}>Scheda Utente</a>
                    </td>
                  </tr>
                  <tr>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                            <img style={{ "width": "50px", "height": "50px", "borderRadius": "4px" }} src={toAbsoluteUrl("/media/users/100_12.jpg")} />
                          </span>
                        </div>
                        <div>
                          <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Nicola
                          De Simone</a>
                          <span className="text-muted font-weight-bold d-block">Team Director</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €9,000,000
                      </span>
                      <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €720
                      </span>
                      <span className="text-warning font-weight-bold">
                        Da verificare
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Emilia Romagna
                      </span>
                      <span className="text-muted font-weight-bold">
                        Bologna
                      </span>
                    </td>
                    <td>
                      <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        5 su 5
                      </span>
                    </td>
                    <td className="pr-0 text-right">
                      <a href="#" className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => setModalShow(true)}>Scheda Utente</a>
                    </td>
                  </tr>
                  <tr>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                            <img style={{ "width": "50px", "height": "50px", "borderRadius": "4px" }} src={toAbsoluteUrl("/media/users/100_5.jpg")} />
                          </span>
                        </div>
                        <div>
                          <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Antonio
                          Broegg</a>
                          <span className="text-muted font-weight-bold d-block">Mobile Guide</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €1,000,000
                      </span>
                      <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €20
                      </span>
                      <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Puglia
                      </span>
                      <span className="text-muted font-weight-bold">
                        Bari
                      </span>
                    </td>
                    <td>
                      <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        5 su 5
                      </span>
                    </td>
                    <td className="pr-0 text-right">
                      <a href="#" className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => setModalShow(true)}>Scheda Utente</a>
                    </td>
                  </tr>
                  <tr>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                            <img style={{ "width": "50px", "height": "50px", "borderRadius": "4px" }} src={toAbsoluteUrl("/media/users/100_9.jpg")} />
                          </span>
                        </div>
                        <div>
                          <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Luigi
                          Mazza</a>
                          <span className="text-muted font-weight-bold d-block">Cosulente</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €2,000,000
                      </span>
                      <span className="text-danger font-weight-bold">
                        Sospeso
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €400
                      </span>
                      <span className="text-danger font-weight-bold">
                        Sospeso
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Lombardia
                      </span>
                      <span className="text-muted font-weight-bold">
                        Milano
                      </span>
                    </td>
                    <td>
                      <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        5 su 5
                      </span>
                    </td>
                    <td className="pr-0 text-right">
                      <a href="#" className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => setModalShow(true)}>Scheda Utente</a>
                    </td>
                  </tr>

                  <tr>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">

                            <img style={{ "width": "50px", "height": "50px", "borderRadius": "4px" }} src={toAbsoluteUrl("/media/users/100_1.jpg")} />

                          </span>
                        </div>
                        <div>
                          <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Mario
                          Rossi</a>
                          <span className="text-muted font-weight-bold d-block">Mobile Specialist</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €8,000,000
                      </span>
                      <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €520
                      </span>
                      <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Lombardia
                      </span>
                      <span className="text-muted font-weight-bold">
                        Milano
                      </span>
                    </td>
                    <td>
                      <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        5 su 5
                      </span>
                    </td>
                    <td className="pr-0 text-right">
                      <a href="#" className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => setModalShow(true)}>Scheda Utente</a>
                    </td>
                  </tr>
                  <tr>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                            <img style={{ "width": "50px", "height": "50px", "borderRadius": "4px" }} src={toAbsoluteUrl("/media/users/100_3.jpg")} />
                          </span>
                        </div>
                        <div>
                          <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Michele
                          Loffredo</a>
                          <span className="text-muted font-weight-bold d-block">Team Manager</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €3,000,000
                      </span>
                      <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €120
                      </span>
                      <span className="text-warning font-weight-bold">
                        Da Verificare
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Campania
                      </span>
                      <span className="text-muted font-weight-bold">
                        Napoli
                      </span>
                    </td>
                    <td>
                      <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        5 su 5
                      </span>
                    </td>
                    <td className="pr-0 text-right">
                      <a href="#" className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => setModalShow(true)}>Scheda Utente</a>
                    </td>
                  </tr>
                  <tr>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                            <img style={{ "width": "50px", "height": "50px", "borderRadius": "4px" }} src={toAbsoluteUrl("/media/users/100_12.jpg")} />
                          </span>
                        </div>
                        <div>
                          <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Nicola
                          De Simone</a>
                          <span className="text-muted font-weight-bold d-block">Team Director</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €9,000,000
                      </span>
                      <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €720
                      </span>
                      <span className="text-warning font-weight-bold">
                        Da verificare
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Emilia Romagna
                      </span>
                      <span className="text-muted font-weight-bold">
                        Bologna
                      </span>
                    </td>
                    <td>
                      <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        5 su 5
                      </span>
                    </td>
                    <td className="pr-0 text-right">
                      <a href="#" className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => setModalShow(true)}>Scheda Utente</a>
                    </td>
                  </tr>
                  <tr>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                            <img style={{ "width": "50px", "height": "50px", "borderRadius": "4px" }} src={toAbsoluteUrl("/media/users/100_5.jpg")} />
                          </span>
                        </div>
                        <div>
                          <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Antonio
                          Broegg</a>
                          <span className="text-muted font-weight-bold d-block">Mobile Guide</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €1,000,000
                      </span>
                      <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €20
                      </span>
                      <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Puglia
                      </span>
                      <span className="text-muted font-weight-bold">
                        Bari
                      </span>
                    </td>
                    <td>
                      <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        5 su 5
                      </span>
                    </td>
                    <td className="pr-0 text-right">
                      <a href="#" className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => setModalShow(true)}>Scheda Utente</a>
                    </td>
                  </tr>
                  <tr>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                            <img style={{ "width": "50px", "height": "50px", "borderRadius": "4px" }} src={toAbsoluteUrl("/media/users/100_9.jpg")} />
                          </span>
                        </div>
                        <div>
                          <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Luigi
                          Mazza</a>
                          <span className="text-muted font-weight-bold d-block">Cosulente</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €2,000,000
                      </span>
                      <span className="text-danger font-weight-bold">
                        Sospeso
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €400
                      </span>
                      <span className="text-danger font-weight-bold">
                        Sospeso
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Lombardia
                      </span>
                      <span className="text-muted font-weight-bold">
                        Milano
                      </span>
                    </td>
                    <td>
                      <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        5 su 5
                      </span>
                    </td>
                    <td className="pr-0 text-right">
                      <a href="#" className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => setModalShow(true)}>Scheda Utente</a>
                    </td>
                  </tr>

                  <tr>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">

                            <img style={{ "width": "50px", "height": "50px", "borderRadius": "4px" }} src={toAbsoluteUrl("/media/users/100_1.jpg")} />

                          </span>
                        </div>
                        <div>
                          <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Mario
                          Rossi</a>
                          <span className="text-muted font-weight-bold d-block">Mobile Specialist</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €8,000,000
                      </span>
                      <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €520
                      </span>
                      <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Lombardia
                      </span>
                      <span className="text-muted font-weight-bold">
                        Milano
                      </span>
                    </td>
                    <td>
                      <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        5 su 5
                      </span>
                    </td>
                    <td className="pr-0 text-right">
                      <a href="#" className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => setModalShow(true)}>Scheda Utente</a>
                    </td>
                  </tr>
                  <tr>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                            <img style={{ "width": "50px", "height": "50px", "borderRadius": "4px" }} src={toAbsoluteUrl("/media/users/100_3.jpg")} />
                          </span>
                        </div>
                        <div>
                          <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Michele
                          Loffredo</a>
                          <span className="text-muted font-weight-bold d-block">Team Manager</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €3,000,000
                      </span>
                      <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €120
                      </span>
                      <span className="text-warning font-weight-bold">
                        Da Verificare
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Campania
                      </span>
                      <span className="text-muted font-weight-bold">
                        Napoli
                      </span>
                    </td>
                    <td>
                      <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        5 su 5
                      </span>
                    </td>
                    <td className="pr-0 text-right">
                      <a href="#" className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => setModalShow(true)}>Scheda Utente</a>
                    </td>
                  </tr>
                  <tr>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                            <img style={{ "width": "50px", "height": "50px", "borderRadius": "4px" }} src={toAbsoluteUrl("/media/users/100_12.jpg")} />
                          </span>
                        </div>
                        <div>
                          <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Nicola
                          De Simone</a>
                          <span className="text-muted font-weight-bold d-block">Team Director</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €9,000,000
                      </span>
                      <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €720
                      </span>
                      <span className="text-warning font-weight-bold">
                        Da verificare
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Emilia Romagna
                      </span>
                      <span className="text-muted font-weight-bold">
                        Bologna
                      </span>
                    </td>
                    <td>
                      <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        5 su 5
                      </span>
                    </td>
                    <td className="pr-0 text-right">
                      <a href="#" className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => setModalShow(true)}>Scheda Utente</a>
                    </td>
                  </tr>
                  <tr>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                            <img style={{ "width": "50px", "height": "50px", "borderRadius": "4px" }} src={toAbsoluteUrl("/media/users/100_5.jpg")} />
                          </span>
                        </div>
                        <div>
                          <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Antonio
                          Broegg</a>
                          <span className="text-muted font-weight-bold d-block">Mobile Guide</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €1,000,000
                      </span>
                      <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €20
                      </span>
                      <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Puglia
                      </span>
                      <span className="text-muted font-weight-bold">
                        Bari
                      </span>
                    </td>
                    <td>
                      <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        5 su 5
                      </span>
                    </td>
                    <td className="pr-0 text-right">
                      <a href="#" className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => setModalShow(true)}>Scheda Utente</a>
                    </td>
                  </tr>
                  <tr>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                            <img style={{ "width": "50px", "height": "50px", "borderRadius": "4px" }} src={toAbsoluteUrl("/media/users/100_9.jpg")} />
                          </span>
                        </div>
                        <div>
                          <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Luigi
                          Mazza</a>
                          <span className="text-muted font-weight-bold d-block">Cosulente</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €2,000,000
                      </span>
                      <span className="text-danger font-weight-bold">
                        Sospeso
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €400
                      </span>
                      <span className="text-danger font-weight-bold">
                        Sospeso
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Lombardia
                      </span>
                      <span className="text-muted font-weight-bold">
                        Milano
                      </span>
                    </td>
                    <td>
                      <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{ width: "5.5rem" }} />
                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        5 su 5
                      </span>
                    </td>
                    <td className="pr-0 text-right">
                      <a href="#" className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => setModalShow(true)}>Scheda Utente</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
