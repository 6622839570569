import React from "react";
import {
  MixedWidget1,
  MixedWidget14,
  ListsWidget9,
  StatsWidget11,
  StatsWidget12,
  ListsWidget1,
  AdvanceTablesWidget2,
  AdvanceTablesWidget4,
  AdvanceTablesWidget4AllAgent,
  ListsWidget3,
  ListsWidget4,
  ListsWidget8,
} from "../widgets";
export function Demo1DashboardCoseDaFare() {
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <ListsWidget4 className="card-stretch gutter-b" />
        </div>
      </div>
    </>
  );
}
