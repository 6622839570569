/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_helpers";

export function AdvanceTablesWidget4({ className }) {
  return (
      <div className={`card card-custom ${className}`}>
        {/* Head */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">Report Agenti</span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">Più di 20+ nuovi agenti</span>
          </h3>
          <div className="card-toolbar">
            <a href="#" className="btn btn-info font-weight-bolder font-size-sm mr-3">Nuovo Report</a>
            <a href="#" className="btn btn-danger font-weight-bolder font-size-sm">Crea</a>
          </div>
        </div>
        {/* Body */}
        <div className="card-body pt-0 pb-3">
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-7" style={{minWidth: "250px"}}><span className="text-dark-75">Agente</span></th>
                  <th style={{minWidth: "100px"}}>Profitti</th>
                  <th style={{minWidth: "100px"}}>Provvigioni</th>
                  <th style={{minWidth: "100px"}}>Località</th>
                  <th style={{minWidth: "130px"}}>Valutazione</th>
                  <th style={{minWidth: "80px"}}/>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">

                              <img style={{"width":"50px","height":"50px","borderRadius":"4px"}}src={toAbsoluteUrl("/media/users/100_1.jpg")}/>

                          </span>
                      </div>
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Mario
                          Rossi</a>
                        <span className="text-muted font-weight-bold d-block">Mobile Specialist</span>
                      </div>
                    </div>
                  </td>
                  <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €8,000,000
                      </span>
                    <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                  </td>
                  <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €520
                      </span>
                    <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                  </td>
                  <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Lombardia
                      </span>
                    <span className="text-muted font-weight-bold">
                        Milano
                      </span>
                  </td>
                  <td>
                    <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{width: "5.5rem"}}/>
                    <span className="text-muted font-weight-bold d-block font-size-sm">
                        5 su 5
                      </span>
                  </td>
                  <td className="pr-0 text-right">
                    <a href="#" className="btn btn-light-success font-weight-bolder font-size-sm">Scheda Agente</a>
                  </td>
                </tr>
                <tr>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                          <img style={{"width":"50px","height":"50px","borderRadius":"4px"}}src={toAbsoluteUrl("/media/users/100_3.jpg")}/>
                          </span>
                      </div>
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Michele
                          Loffredo</a>
                        <span className="text-muted font-weight-bold d-block">Team Manager</span>
                      </div>
                    </div>
                  </td>
                  <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €3,000,000
                      </span>
                    <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                  </td>
                  <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €120
                      </span>
                    <span className="text-warning font-weight-bold">
                        Da Verificare
                      </span>
                  </td>
                  <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Campania
                      </span>
                    <span className="text-muted font-weight-bold">
                        Napoli
                      </span>
                  </td>
                  <td>
                    <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{width: "5.5rem"}}/>
                    <span className="text-muted font-weight-bold d-block font-size-sm">
                        5 su 5
                      </span>
                  </td>
                  <td className="pr-0 text-right">
                    <a href="#" className="btn btn-light-success font-weight-bolder font-size-sm">Scheda Agente</a>
                  </td>
                </tr>
                <tr>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                          <img style={{"width":"50px","height":"50px","borderRadius":"4px"}}src={toAbsoluteUrl("/media/users/100_12.jpg")}/>
                          </span>
                      </div>
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Nicola
                          De Simone</a>
                        <span className="text-muted font-weight-bold d-block">Team Director</span>
                      </div>
                    </div>
                  </td>
                  <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €9,000,000
                      </span>
                    <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                  </td>
                  <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €720
                      </span>
                    <span className="text-warning font-weight-bold">
                        Da verificare
                      </span>
                  </td>
                  <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Emilia Romagna
                      </span>
                    <span className="text-muted font-weight-bold">
                        Bologna
                      </span>
                  </td>
                  <td>
                    <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{width: "5.5rem"}}/>
                    <span className="text-muted font-weight-bold d-block font-size-sm">
                        5 su 5
                      </span>
                  </td>
                  <td className="pr-0 text-right">
                    <a href="#" className="btn btn-light-success font-weight-bolder font-size-sm">Scheda Agente</a>
                  </td>
                </tr>
                <tr>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                          <img style={{"width":"50px","height":"50px","borderRadius":"4px"}}src={toAbsoluteUrl("/media/users/100_5.jpg")}/>
                          </span>
                      </div>
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Antonio
                          Broegg</a>
                        <span className="text-muted font-weight-bold d-block">Mobile Guide</span>
                      </div>
                    </div>
                  </td>
                  <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €1,000,000
                      </span>
                    <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                  </td>
                  <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €20
                      </span>
                    <span className="text-success font-weight-bold">
                        Verificato
                      </span>
                  </td>
                  <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Puglia
                      </span>
                    <span className="text-muted font-weight-bold">
                        Bari
                      </span>
                  </td>
                  <td>
                    <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{width: "5.5rem"}}/>
                    <span className="text-muted font-weight-bold d-block font-size-sm">
                        5 su 5
                      </span>
                  </td>
                  <td className="pr-0 text-right">
                    <a href="#" className="btn btn-light-success font-weight-bolder font-size-sm">Scheda Agente</a>
                  </td>
                </tr>
                <tr>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                          <img style={{"width":"50px","height":"50px","borderRadius":"4px"}}src={toAbsoluteUrl("/media/users/100_9.jpg")}/>
                          </span>
                      </div>
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Luigi
                          Mazza</a>
                        <span className="text-muted font-weight-bold d-block">Cosulente</span>
                      </div>
                    </div>
                  </td>
                  <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €2,000,000
                      </span>
                    <span className="text-danger font-weight-bold">
                        Sospeso
                      </span>
                  </td>
                  <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        €400
                      </span>
                    <span className="text-danger font-weight-bold">
                        Sospeso
                      </span>
                  </td>
                  <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Lombardia
                      </span>
                    <span className="text-muted font-weight-bold">
                        Milano
                      </span>
                  </td>
                  <td>
                    <img src={toAbsoluteUrl("/media/logos/stars.png")} alt="image" style={{width: "5.5rem"}}/>
                    <span className="text-muted font-weight-bold d-block font-size-sm">
                        5 su 5
                      </span>
                  </td>
                  <td className="pr-0 text-right">
                    <a href="#" className="btn btn-light-success font-weight-bolder font-size-sm">Scheda Agente</a>
                  </td>
                </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  );
}
