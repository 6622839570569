import React from "react";
import {useSubheader} from "../../_metronic/layout";
import {
  Dashboard
} from "../../_metronic/_partials";

export function DashboardPage() {

  const suhbeader = useSubheader();
  suhbeader.setTitle("Dashboard");

  return <Dashboard />;
}
