import React from "react";
import {useSubheader} from "../../_metronic/layout";
import {
  DashboardCoseDaFare
} from "../../_metronic/_partials";

export const CoseDaFare = () => {

  const suhbeader = useSubheader();
  suhbeader.setTitle("Cose Da fare");

  return <DashboardCoseDaFare />;
};
